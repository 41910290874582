<template>
  <fragment> 
    <AnalysisVolvo v-if="role.isVolvo" />
    <AnalysisDefault v-else/>
  </fragment> 
</template>

<script>
import Vue from 'vue'
import {getRole} from '@/utils/getRole';
import AnalysisDefault from './analysisDefault.vue';
import AnalysisVolvo from './analysisVolvo.vue';


export default {
  data() {
      return {
        role: getRole(),
      }
  },
  components:{
    AnalysisDefault,
    AnalysisVolvo,
  },
  created() {
  },
  mounted () {
  },
}
</script>

<style lang="less">

</style>

