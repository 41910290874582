<template>
  <div class="page-dealer-audi-af">
    <div class="box-top" v-if="role.isAfDealerBoth && role.isReport">
      <div class="fixed">
        <div class="box-jump" @click="$router.push({name: 'dealerIndex'})">销售平台
          <img src="@/assets/i_change.png"/>
        </div>
      </div>
    </div>
    <div class="box-date">
      <DataSearch :qrTitle="role.isReport? '数据统计':'数据平台'" :dateQuery="ruleForm" @change="changeDate"/>
    </div>
    <h3 class="title">
      <strong>接待总览</strong>
    </h3>
    <div :class="itemClass">
      <div class="score" v-for="(item, idx) in totalAccount" :key="idx">
        <div class="num"><strong>{{item.value || '-'}}</strong></div>
        <div class="name" v-if="item.unit">{{item.title}}({{item.unit}})</div>
        <div class="name" v-else>{{item.title}}</div>
      </div>
    </div>

    <h3 class="title">
      <strong>开口率趋势</strong>
      <span>
          <el-radio-group v-model="dateType" @change="getTrend">
            <el-radio v-for="item in dateList"
              :key="item" :label="item" style="margin-right: 10px;"></el-radio>
          </el-radio-group>
     </span>
    </h3>

    <div class="sec">
      <div id="chartTrend" style="width: 100%; height: 300px;padding-top: 0.5rem;"></div>
    </div>

    <h3 class="title">
      <strong>员工开口率</strong>
      <span>
          <el-radio-group v-model="scoreOrder" @change="onSort">
            <el-radio :key="1" :label="1">从高到低</el-radio>
            <el-radio :key="0" :label="0">从低到高</el-radio>
          </el-radio-group>
      </span>
    </h3>
    
    <div class="sec">
      <div class="box-filter">
        <div class="item" v-for="(item,idx) in stepData" :key="idx" @click="getStaffList(item)">
          <span :class="item.label === curStep.label ? 'i sel': 'i'"></span>
          {{item.label}}
        </div>
      </div>
      <div id="chartStep" style="width: 100%; height: 300px;"></div>
    </div>

    <h3 class="title">
      <strong>接待员工检核</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <el-table class="box-tbl" 
          :data="stafftableData"
          @row-click="handleStaffClick"
          @sort-change="onSortChange"
          >
          <el-table-column
            prop="admin_name"
            width="80"
            label="接待人员"
            >
          </el-table-column>
          <el-table-column v-for="item in stepData"
            :width="10 +item.label.length * 14 + (item.sort ? 20 : 0)"
            :key="item.column"
            :prop="item.column"
            :label="item.label"
            :sortable="!!item.sort"
            >
          </el-table-column>
          <!--
          <el-table-column
            prop="admin_name"
            width="80"
            label="接待人员"
            >
          </el-table-column>
          <el-table-column
            prop="score"
            width="50"
            label="总分">
          </el-table-column>
          <el-table-column
            prop="jd"
            width="80"
            label="接待环节"
            >
          </el-table-column>
          <el-table-column
            prop="kjwts"
            width="80"
            label="开具任务"
            >
          </el-table-column>
          <el-table-column
            prop="wxzx"
            width="80"
            label="维修增项"
            >
          </el-table-column>
          <el-table-column
            prop="tzkhqc"
            width="80"
            label="通知取车"
            >
          </el-table-column>
          <el-table-column
            prop="jsjsqd"
            width="80"
            label="解释结算"
            >
          </el-table-column>
          <el-table-column
            prop="dkhjs"
            width="50"
            label="结算"
            >
          </el-table-column>
          <el-table-column
            prop="sk"
            width="50"
            label="送客">
          </el-table-column>
          -->
        </el-table>
    </div>

    <h3 class="title">
      <strong>接待客户明细</strong>
    </h3>
    <div class="sec" style="padding:0 0 0.5rem 0; margin-bottom: 0.5rem">
      <el-table class="box-tbl" 
          :data="tableData"
          @row-click="handleRowClick"
          >
          <el-table-column
            prop="order_no"
            width="60"
            label="主工单"
            >
          </el-table-column>
          <el-table-column
            prop="car_plate"
            width="70"
            label="车牌号"
            >
          </el-table-column>
          <el-table-column
            prop="in_time"
            width="90"
            label="进厂时间">
          </el-table-column>
          <el-table-column
            prop="invoice_time"
            width="90"
            label="开单时间">
          </el-table-column>
          <el-table-column
            prop="score"
            width="40"
            label="总分">
          </el-table-column>
        </el-table>
        <div v-if="tableData.length >= 10" style="text-align: center; padding: 1rem 0; color: #999" @click="jumpToList('afCheckDetailList')">点击查看更多 <i class="el-icon-arrow-right"></i></div>
    </div>

    <NavBar tab="售后分析"></NavBar>
  </div>
</template>

<script>
import Vue from 'vue'
import { postForm, fetchGet } from '@/api'
import NavBar from '@/components/afNavbarDealer.vue';
import DataSearch from '@/components/afDataSearch.vue';
import * as echarts from 'echarts'
import { afStepOpts } from '@/utils/chart'
import { getFormatDate } from "@/dbCommon/utils";
import {getRole} from '@/utils/getRole';


export default {
  data() {
      return {
        role: getRole(),
        scoreOrder: 1,
        selPop: {
          target_model: false,
          user_level: false,
        },
        selObj: {
          target_model: '',
          user_level: '',
        },
        tagData: [],
        tagTab: 1,
        curTag: {},
        totalAccount: [],
        stafftableData: [],
        stepData: [],
        curStep: {},
        tableData: [],
        carModelOpts: [],
        userLevelOpts: [],
        inNum: {
          r1: '首次进店',
          r2: '首次进店',
          r3: '首次进店',
          r4: '首次进店',
        },
        inNumList: ['首次进店', '再次进店'],
        dateList: [/*'日趋势',*/ '周趋势', '月趋势'],
        dateType: '周趋势',
        ruleForm: {
          date_type: '今日'
        },
        online: false,
        electricity: 0,
      }
  },
  computed:{
    itemClass() {
      if (this.totalAccount.length % 2 == 0) {
        return 'box-result sec';
      }
      return 'box-result even sec';
    }
  },
  components:{
    NavBar,
    DataSearch,
  },
  methods : {
    jumpToList(name) {
      const map = {
        '今日': 'yesterday',
        '本周': 'week',
        '本月': 'month',
        '上月': 'lastMonth',
        '自定义': 'custom',
       }
      const {date_type, ...rest} = this.ruleForm;
      let q = {};
      if (date_type == '自定义') {
        q = {
          ...rest,
          date_type: map[date_type],
        }
      } else {
        q = {
          date_type: map[date_type],
        }
      }
      this.$router.push({name, query: {...q}});
    },
    handleStaffClick(r, e, c) {
      //this.$router.push({name: 'afDealerStaff', query: {id:r.admin_id, ...this.ruleForm}});
    },
    handleRowClick(r, e, c) {
      this.$router.push({name: 'afReceptionShow', query: {id:r.id}});
    },
    changeDate(v) {
      this.ruleForm = {
        ...this.ruleForm,
        ...v,
      }
      this.$router.replace({name: 'afDealerAnalysis', query: {...this.ruleForm}});
      this.loadData();
    },
    selDateType(v) {
      this[v.key] = v.value;
    },
    onSort(i) {
      this.scoreOrder = i;
      this.getStaffList();
    },
    onSortChange({column, prop, order}) {
      const asc = order === 'ascending';
      this.scoreOrder = asc ? 0 : 1;
      this.getStaffList({column: prop})
    },
    onSel(obj) {
      this.selPop = {};
      this.selObj = {
        ...this.selObj,
        ...obj
      }
      this.getList();
    },
    onToggle(k) {
      this.selPop = {
        [k]: !this.selPop[k],
      }
      setTimeout(() => {
        const dom = document.getElementById(this.selObj[k]);
        dom && dom.scrollIntoView({
          behavior: 'smooth',
        });
      }, 0);
    },
    getTotal() {
      const {ruleForm} = this;
      fetchGet('h5/sa_company/statisticalAnalysis1', {
        ...ruleForm,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }

          this.totalAccount = res.data.totalAccount || [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getTrend() {
      const {ruleForm} = this;
      fetchGet('h5/sa_company/statisticalAnalysis2', {
        //...ruleForm,
        date_type: this.dateType,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.trendList = res.data || [];
        if (!this.trendList.length) {
          return;
        }
//const dataLists= [{label: "1970-01-01", value: 40},{label: "1970-01-02", value: 30},{label: "1970-01-03", value: 80},{label: "1970-01-01", value: 40},{label: "1970-01-02", value: 30},{label: "1970-01-03", value: 80}];
        const itemList = this.trendList.map((o) => ({
            title: o.title,
            unit: '',
            labelArr: o.dataLists.map(o => o.label),
            valueArr: o.dataLists.map(o => o.value),
        }));
        this.trendChart.clear();
        this.trendChart.setOption(afLengthTrendOpts(itemList));
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getStaffList(item) {
      const {ruleForm} = this;
      const {column} = item || {};
      fetchGet('h5/sa_company/statisticalAnalysis3', {
        ...ruleForm,
        column: column || this.curStep.column,
        sort: this.scoreOrder,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.stepData = res.data.title || {};
        this.stafftableData = res.data.lists || [];

        this.curStep = item ? item : this.stepData[0];
        const d = this.stafftableData || [];
        const labelArr = d.map(o => o.admin_name);
        const valueArr = d.map(o => o.num);
        this.stepChart.clear();
        this.stepChart.setOption(afStepOpts({
          labelArr,
          valueArr,
        }));

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      const {ruleForm, order_by} = this;
      const {target_model, user_level} = this.selObj;
      fetchGet('/h5/sa_company/statisticalAnalysis5', {
        ...ruleForm,
      }).then(res => {
        if (res.status === 1) {
          if (!res.data) {
            return;
          }
        this.tableData = res.data || [];

        } else {
          this.$message.error(res.msg);
        }
      });
    },
    
    loadData() {
      this.getTotal();
      this.getStaffList();
      this.getList();
    },
  },
  created() {
    /*
    const {start_date: s, end_date: e, date_type} = this.$router.currentRoute.query || {};
    const { start_date, end_date } = getFormatDate('yesterday');
    const isYestoday = start_date == s && end_date == e;

    if (isYestoday) {
      this.ruleForm = {
        ...this.ruleForm,
        start_date: s,
        end_date: e,
      }
    } else if (s || e) {
     this.ruleForm = {
        ...this.ruleForm,
        date_type: '自定义',
        start_date: s,
        end_date: e,
      }
    }
    */

    const q = this.$router.currentRoute.query || {};
    this.ruleForm = {
        ...this.ruleForm,
        ...q,
    }
    this.loadData();
    this.getTrend();
  },
  mounted () {
    const dTrend = document.getElementById('chartTrend');
    this.trendChart = echarts.init(dTrend);

    const dStep = document.getElementById('chartStep');
    this.stepChart = echarts.init(dStep);
  },
}


function afLengthTrendOpts(list) {

const borderColor = '#D2DCF3';
const fontColor = '#626D83';
const labelArr = list[0].labelArr;
const nameArr = list.map(o => o.title);
const colors = ['#f1707d', '#f2debd', '#b8d38f', '#ddff95', '#ff9b6a', '#f1b8f1', '#b8f1ed', '#b8f1cc', '#f1b8f1', '#f1f1b8'];
const series = list.map((o,i) => {
  return {
    name: `${o.title}`,
    type: 'line',
    symbol: 'none',
    yAxisIndex: 0,
    itemStyle: {
        color: colors[i]
    },
    areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: colors[i]
        }, {
            offset: 1,
            color: '#fff'
        }])
    },
    data: o.valueArr
  }
});

const yAxisOpts = {
  type: 'value',
  minInterval: 1,
  nameTextStyle: {
    color: fontColor,
  },
  axisLabel: {
    color: fontColor,
    show: true,
  },
  axisTick: {
    show: false
  },
  axisLine: {
    show: false,
    lineStyle: {
        color: borderColor
    }
  },
};
  const option = {
      animation: false,
      legend: {
          icon: 'rect',
          itemWidth: 8,
          itemHeight: 5,
          data: nameArr,
          top: '0px'
      },
      tooltip: {
          trigger: 'axis',
      },
      xAxis: {
          data: labelArr,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: fontColor,
            show: true,
            interval: 0,
            rotate: labelArr.length > 3 ? 40 : 0,
            textStyle: {
              color: fontColor,
              fontSize: '8px',
            },
          },
          axisLine: {
            //show: false,
            lineStyle: {
                color: borderColor
            }
          },
          axisPointer: {
              lineStyle: {
                  color: fontColor,
                  opacity: 0.5,
                  width: 1
              },
          },
          splitLine: {
              show: false
          }
      },
      yAxis: [
        {
          ...yAxisOpts,
          name: `${list[0].unit}`,
          splitLine: {
              show: false
          },
        },
        {
          ...yAxisOpts,
          name: `${list[1].unit}`,
          splitLine: {
              show: false
          },
        },
      ],
      grid: {
          top: 50,
          left: 30,
          right: 30,
          bottom: 60,
      },
      series,
  };
  return option;
}
</script>

<style lang="less">

.page-dealer-audi-af {
  background: #F2F2F2;

.box-top {
  height: 44px;
  position: relative;

  .fixed {
    height: 44px;
    line-height: 44px;
    top: 0;
    width: 100%;
    max-width: 750px;
    z-index: 1000;
    position: fixed;
    background: #F2F2F2 url(/img/af_bg_main_s.83c6e400.png);
    background-size: 100% auto;
  }

  .box-jump {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #0070C0;
    width: 130px;
    margin: auto;

    img {
      height: 15px;
    }
  }
}
  .el-radio {
    font-weight: normal;
  }
  .el-radio__inner {
    background: url('~@/assets/radio.png') center center no-repeat;
    background-size: cover;
    border: 0;
    &::after {
      display: none;
    }
  }
  .el-radio__input.is-checked .el-radio__inner {
    background: url('~@/assets/radio_sel.png') center center no-repeat;
    background-size: cover;

  }
  .el-radio-group {
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
  }
  .sort-step {
    border-radius: 0.3rem;
    padding: 0.3rem 1rem;
    background: #fff;
    color: #377EEA;
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-size: 0.7rem;
    .icon-sort {
      margin-left: 0.2rem;
      display: flex;
      flex-direction: column;
      font-size: 12px;

      i {
        color: #bfbfbf;
        margin: -2px 0;
      }
    }
  }
  
  .box-tbl {
    &.el-table::before {
      background-color: transparent;
    }
    &.el-table, tr, .el-table__cell, th.el-table__cell.is-leaf {
      background: transparent;
      line-height: 1.5;
      border: 0;
      padding: 0;

      .cell {
        padding: 0.3rem 0.2rem;
        line-height: 1.2;
      }
    }
    .el-table__header-wrapper {
      background: #E6F1F9;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      th{
        color: #666;
        font-size: 0.6rem;
        border: 0;
        font-weight: normal;
      }
    }

    .el-table__body-wrapper {
      tr {
        td {
          font-size: 0.6rem;
          border: 0;
          color: #000;
          line-height: 1.2;
        }
      }
    }
  }

  h3.title {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;

    >span {
      flex: 1;
      text-align: right;
      .el-radio__input {
        width: 20px;
      }
      .el-radio__label {
        padding-left: 0;
        color: #000;
      }
    }
  }

  .box-filter {
    padding: 1rem 0;
    .item {
      display: inline-block;
      padding: 0.25rem 0;
      cursor: pointer;
      margin-right: 1rem;
      .i {
        width: 0.3rem;
        height: 0.3rem;
        background: #ccc;
        display: inline-block;
        margin-right: 0.15rem;
        vertical-align: middle;

        &.sel {
          background: #0070C0;
        }
      }
    }
  }
  
  .box-tag {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    span {
      font-size: 0.7rem;
      line-height: 2rem;
      width: 47%;
      text-align: center;
      border: 1px solid #F5F5F5;
      border-radius: 10px;
      background: #F5F5F5;

      &.sel {
        border: 1px solid #1890ff;
        color: #377EEA;
        background: #fff;
      }
    }
  }

  .box-result {
    padding: 0;
    display: flex;
    flex- wrap: wrap;

    &.even {
      .score:last-child {
        min-width: 100%;
      }
    }
    .score {
      min-width: 50%;
      text-align: center;
      border-top: 1px solid #EFEFEF;
      border-left: 1px solid #EFEFEF;
      padding: 1rem 0;

      &:nth-child(1), &:nth-child(2) {
        border-top: 0;
      }
      &:nth-child(odd) {
        border-left: 0;
      }

      .num {
        font-size: 0.9rem;
      }
      .name {
        font-size: 0.6rem;
        color: #788798;
      }
    }
  }

  .box-date {
    background: url('~@/assets/af_bg_main.png') top center no-repeat;
    background-size: 100% auto;
    padding: 0.5rem 0rem 0.5rem 0.75rem;
  }
}
</style>

